import CardsScroll from "shared/ui/slider/cards-scroll/ui"

import styles from './styles.module.scss';
import Card from "shared/ui/slider/card/ui";

import { IconCertificate, IconCode1, IconCommunity, IconInfrastructure, IconPractice } from "./icons";

export const Slider = () => {

    return (
        <div className={styles.slider} id="get">
            <div className={styles.__in}>

                <div className={styles.title}>
                    Что вы получаете?
                </div>

                <CardsScroll wCard={360}>
                    <Card key='id1'>
                        <div className={styles.box}>
                            <div className={styles.icon}>
                                <IconCode1 width={74} height={74} />
                            </div>
                            <div className={styles.name}>
                                Востребованную профессию
                            </div>
                            <div className={styles.description}>
                                IT-компании в настоящее время сталкиваются с серьезной нехваткой квалифицированный разработчиков. Обучение в Ансаре предоставляет возможность получить навыки, необходимые для успешной работы в сфере IT.
                            </div>
                        </div>
                    </Card>
                    <Card key='id2'>
                        <div className={styles.box}>
                            <div className={styles.icon}>
                                <IconInfrastructure width={74} height={74} />
                            </div>
                            <div className={styles.name}>
                                Современную инфраструктуру
                            </div>
                            <div className={styles.description}>
                                Школа предоставляет современные учебные кластеры, оснащенные всем необходимым компьютерным оборудованием и софтом. Для обеспечения комфортного обучения и перерывов между занятиями имеются также зоны отдыха.
                            </div>
                        </div>
                    </Card>
                    <Card key='id3'>
                        <div className={styles.box}>
                            <div className={styles.icon}>
                                <IconCommunity width={74} height={74} />
                            </div>
                            <div className={styles.name}>
                                Отзывчивое комьюнити
                            </div>
                            <div className={styles.description}>
                                Ученики школы Ansar поддерживают активную коммуникацию друг с другом, обмениваясь опытом, обсуждая новости из сферы информационных технологий, оказывая взаимопомощь в работе и на проектах. Мы приглашаем вас стать частью нашего сообщества.
                            </div>
                        </div>
                    </Card>
                    <Card key='id4'>
                        <div className={styles.box}>
                            <div className={styles.icon}>
                                <IconPractice width={74} height={74} />
                            </div>
                            <div className={styles.name}>
                                Практический опыт
                            </div>
                            <div className={styles.description}>
                                Ученики школы Ansar в ходе обучения решают реальные задачи и приобретают практические навыки разработки IT-проектов.
                            </div>
                        </div>
                    </Card>
                    <Card key='id5'>
                        <div className={styles.box}>
                            <div className={styles.icon}>
                                <IconCertificate width={74} height={74} />
                            </div>
                            <div className={styles.name}>
                                Сертификат Ansar
                            </div>
                            <div className={styles.description}>
                                Участники получают сертификат базового или продвинутого уровня, который подтверждает практические навыки в программировании.
                            </div>
                        </div>
                    </Card>
                </CardsScroll>
            </div>
        </div>
    )
}