import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type TCardProps = {
    children: ReactNode
}

const Card: FC<TCardProps> = ({ children }) => {
    return (
        <div className={`${styles.card}`}>
            {children}
        </div>
    )
}

export default Card