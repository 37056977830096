import { FC } from "react";

import styles from './styles.module.scss'
import { IconInstagram, IconPresent } from "shared/assets/icons";

export const Footer: FC = () => {
    return (
        <div className={styles.footer} id="contacts">
            <div className={styles.__in}>

                <div className={styles.title}>
                    Наши контакты
                </div>

                <div className={styles.main}>

                    <div className={styles.contacts}>

                        <div className={styles.box}>
                            <div className={styles.sub_title}>Грозный</div>
                            <div className={styles.list}>

                                <div className={`${styles.li}`}>geldabaev@yandex.ru</div>
                                <div className={`${styles.li}`}>+7 928 021 5225</div>
                                <div className={`${styles.li}`}>ул. Энгельса 35</div>

                            </div>
                        </div>

                        <div className={`${styles.box} ${styles.tg}`}>
                            <div className={styles.sub_title}>Каналы</div>

                            <div className={styles.list}>

                                <a href="https://t.me/ansar_live"><div className={`${styles.li}`}><span>Ansar Live</span></div></a>
                                <a href="https://t.me/it_muslims"><div className={`${styles.li}`}><span>IT Muslims</span></div></a>
                                <a href="https://t.me/it_ansar_company"><div className={`${styles.li}`}><span>Ansar</span></div></a>

                            </div>
                        </div>

                        <div className={`${styles.box} ${styles.tg}`}>
                            <div className={styles.sub_title}>Мы в соц. сетях</div>

                            <div className={styles.list}>

                                <a href="https://instagram.com/ansar_itcompany">
                                    <div className={`${styles.li} ${styles.insta}`}>
                                        <div className={styles.instagram}>
                                            <div className={styles.icon}>
                                                <IconInstagram width={24} height={24} />
                                            </div>
                                            <div className={styles.name}><span>@ansar_itcompany</span></div>
                                        </div>
                                    </div>
                                </a>

                            </div>
                        </div>

                    </div>

                    <div className={`${styles.box} ${styles.presents}`}>
                            <div className={styles.sub_title}>Материалы</div>

                            <div className={styles.list}>

                                <a href="http://itansar.ru/learn" target="__blank">
                                    <div className={`${styles.li} ${styles.insta}`}>
                                        <div className={styles.instagram}>
                                            <div className={styles.icon}>
                                                <IconPresent width={24} height={24} />
                                            </div>
                                            <div className={styles.name}><span>Уроки (Для преподавателя)</span></div>
                                        </div>
                                    </div>
                                </a>

                            </div>
                        </div>

                    <div className={styles.logo}>
                        <div className={styles.name}>
                            <span>Ansar</span> 2024
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}