import { FC, useEffect, useState, useRef} from "react";

import styles from './styles.module.scss'
import { IconT } from "shared/assets/icons";

let prevScrollpos =0
export const Header: FC = () => {

    const [top, setTop] = useState(0)

    const headRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        window.addEventListener("scroll", function() {
            if(!headRef.current) return

            // const height = headRef.current.offsetHeight;

            const pos = window.scrollY;
            // const diff = scrollY - pos;
        
            // elY = Math.min(0, Math.max(-height, elY + diff));

            if (prevScrollpos > pos) {
                setTop(0)
            } else {
                setTop(-80)
            }
            prevScrollpos = pos;
        })

    }, [])
    return (
        <>
            <div className={styles.before} />
            
            <div
                className={styles.header}
                ref={headRef}
                style={{
                    top: top
                }}
            >
                <div className={styles.__in}>

                    <div className={styles.logo}>
                        <IconT />
                    </div>

                    <div className={styles.menu}>
                        {/* <a href="#ansar"><div className={styles.li}><span>Главное</span></div></a> */}
                        <a href="#get"><div className={styles.li}><span>Обучение</span></div></a>
                        <a href="#courses"><div className={styles.li}><span>Направления</span></div></a>
                        <a href="#process"><div className={styles.li}><span>Для кого</span></div></a>
                        <a href="#feedback"><div className={styles.li}><span>Поступить</span></div></a>
                        <a href="#teachers"><div className={styles.li}><span>Преподаватели</span></div></a>
                        <a href="#contacts"><div className={styles.li}><span>Контакты</span></div></a>
                    </div>

                </div>

            </div>
        </>
    )
}