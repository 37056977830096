import { FC, useState } from "react";

import { popoutModel } from "shared/ui/popout-root";

import styles from './styles.module.scss'

export const Feedback: FC = () => {

    const [name, seteName] = useState({ val: '', edit: 0 });
    const [phone, setPhone] = useState({ val: '', edit: 0 });

    const [isSend, setIsSend] = useState(false)

    const onName = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.currentTarget;
        let v = value + '.';

        if (v.slice(-3, -1) === "  ") {
            value = value.slice(0, -1);
        }
        seteName({ val: value.slice(0, 13), edit: 1 });
    }

    const onPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.currentTarget;
        let v = value;

        console.log('v', v.length)

        if (v.length === 0) setPhone({ val: '+7', edit: 1 })
        else {
            setPhone({ val: value.slice(0, 13), edit: 1 });
        }
    }


    const sendForm = () => {


        console.log('sendForm')

        if(isSend) {
            popoutModel.events.setPopout(null)
            return
        }

        if (phone.val.length < 4) {
            return
        }

        fetch('https://app2023.ru/ansar/tg.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                name: name.val,
                phone: phone.val,
                course: 'Нужно помочь выбрать курс'
            })
        })
            // .then(response => response.json())
            .then(result => {

                setIsSend(true)

                console.log('result', result)

            })
    }

    return (
        <div className={styles.feedback} id="feedback">
            <div className={styles.__in}>

                <div className={styles.padding}>

                    <div className={styles.left}>
                        <div className={styles.text}>
                            Оставьте свои контакты, и мы поможем подобрать IT-курс, соответствующий вашей цели.
                        </div>
                    </div>
                    <div className={styles.form}>
                        {!isSend ?
                            <div className={styles.form_layout}>

                                <div className={styles.input_name}>
                                    <div className={styles.top}>
                                        Имя
                                    </div>
                                    <input
                                        type="text"
                                        name="name"
                                        value={name.val}
                                        onChange={onName}
                                    />
                                    <div className={styles.bottom}>
                                        <NameBottom {...name} />
                                    </div>
                                </div>

                                <div className={styles.input_name}>
                                    <div className={styles.top}>
                                        Телефон
                                    </div>
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={phone.val}
                                        onFocus={onPhone}
                                        placeholder="+79001234567"
                                        onChange={onPhone}
                                    />
                                    <div className={styles.bottom}>
                                        <PhoneBottom {...phone} />
                                    </div>
                                </div>


                            </div> :
                            <div className={styles.sendForm}>
                                Заявка успешно отправлена! Ожидайте ответа на указанный номер!
                            </div>}

                        <div className={styles.buttonLayout}>
                            <div
                                onClick={sendForm}
                                className={styles.button}
                            >
                                <div
                                    className={styles.text}
                                >
                                    {isSend ? 'Закрыть' : 'Отправить'}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

type TText = {
    val: string
    edit: number
}


const NameBottom: FC<TText> = (text) => {
    if (text.edit === 0) return <span>&nbsp;</span>
    if (text.val.length === 0) return <span className={styles.error}>Пожалуйста, введите название!</span>
    if (text.val.trim().length < 3) return <span className={styles.error}>Минимальная длина 3 символа!</span>
    if (text.val.trim().length > 12) return <span className={styles.error}>Максимальная длина 20 символов!</span>
    return <span className={styles.success}>&nbsp;</span>
}


const PhoneBottom: FC<TText> = (text) => {
    if (text.edit === 0) return <span>&nbsp;</span>
    if (text.val.length > 6 && !/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/.test(text.val)) return <span className={styles.error}>Введен некорректный номер!</span>
    // if (text.val.trim().length < 3) return <span className={styles.error}>Минимальная длина 3 символа!</span>
    // if (text.val.trim().length > 12) return <span className={styles.error}>Максимальная длина 12 символов!</span>
    return <span className={styles.success}>&nbsp;</span>
}
