import { appModel } from 'app/model';
import { useEffect } from 'react'

export const useApp = () => {

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            const pageWidth = document.documentElement.offsetWidth
            appModel.events.setScrollWidth(pageWidth)
        });
        
        const pageWidth = document.documentElement.offsetWidth
        appModel.events.setScrollWidth(pageWidth)
    }, [])

    return {

    }
}