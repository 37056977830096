import { createEffect, createEvent, createStore, sample } from "effector"
import { useStore } from "effector-react"

const usePopout = () => {
    return {
        data: useStore($popoutStore)
    }
}

export type TPopout =
    | 'training'
    | 'admission'

const setPopout = createEvent<TPopout | null>()

export const $popoutStore = createStore<TPopout | null>(null)
    .on(setPopout, (_, modal) => modal)

export const selectors = {
    usePopout,
}

const popoutFx = createEffect((popout: TPopout | null) => {
    if (popout) window.history.pushState({ popout }, popout)
    return popout
})

sample({
    clock: setPopout,
    target: popoutFx
})

sample({
    clock: popoutFx.doneData,
    target: $popoutStore
})

export const events = {
    setPopout
}