import { FC } from "react";

import styles from './styles.module.scss'

export const Process: FC = () => {
    return (
        <div className={styles.process} id="process">
            <div className={styles.__in}>

                <div className={styles.title}>
                    Какой курс вам больше подойдет
                </div>

                <div className={styles.sub_box}>
                    <div className={styles.sub_title}>
                        На всех курсах вы получите только самые актуальные задания и проекты, которые соответствуют тем, с которыми вы столкнетесь в реальной жизни
                    </div>
                    <div className={styles.sub_list}>
                        <div className={styles.line}>
                            <div className={styles.before}></div>
                            <div className={styles.name}>
                                Добавляйте кейсы в портфолио
                            </div>
                        </div>
                        <div className={styles.line}>
                            <div className={styles.before}></div>
                            <div className={styles.name}>
                                Работайте в команде и погружайтесь в комьюнити
                            </div>
                        </div>
                        <div className={styles.line}>
                            <div className={styles.before}></div>
                            <div className={styles.name}>
                                Консультируйтесь с опытными разработчиками
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.list}>

                    <div className={`${styles.li} ${styles.p1}`}>
                        <div className={styles.tag}>#python</div>
                        <div className={styles.name}>Погружение</div>
                        {/* <div className={styles.icon}><IconTest width={44} height={44} /></div> */}
                        <div className={styles.text}>
                            Курс подходит новичкам в программировании, студентам, желающим освоить Python, людям, ищущим базовые навыки для работы с данными и автоматизации задач, а также тем, кто хочет быстро войти в IT.
                        </div>
                    </div>

                    <div className={`${styles.li}  ${styles.p2}`}>
                        <div className={styles.tag}>#python</div>
                        <div className={styles.name}>Восхождение</div>
                        {/* <div className={styles.icon}><IconTest width={44} height={44} /></div> */}
                        <div className={styles.text}>
                            Курс рекомендуется программистам, желающим расширить свой технологический стек, получить практику и опыт работы с реальными проектами, а также людям, стремящимся повысить эффективность своей работы на фрилансе.
                        </div>
                    </div>

                    <div className={`${styles.li}  ${styles.p3}`}>
                        <div className={styles.tag}>#python</div>
                        <div className={styles.name}>Эксперт</div>
                        {/* <div className={styles.icon}><IconTest width={44} height={44} /></div> */}
                        <div className={styles.text}>
                            Курс рекомендуется программистам, успешно завершившим предыдущие курсы Python, а также разработчикам, стремящимся к глубокому пониманию языка Python и его применению в сложных проектах.
                        </div>
                    </div>

                    {/* <div className={`${styles.li}  ${styles.p4}`}>
                        <div className={styles.tag}>#frontend</div>
                        <div className={styles.name}>Старт</div>
                        <div className={styles.text}>
                            Протестируйте новый интерфейс сайта на курсе «Тестировщик-автоматизатор»
                        </div>
                    </div> */}

                </div>

            </div>
        </div>
    )
}