import { FC } from 'react'

import { PopoutRoot, popoutModel } from 'shared/ui/popout-root'
import { Popout } from 'shared/ui/popout'

import { Admission } from 'widgets/admission/ui'
import { TypeTraining } from 'widgets/type-training/ui'

export const Popouts: FC = () => {

    const popout = popoutModel.selectors.usePopout().data

    console.log('popout', popout)

    return (
        <PopoutRoot activePopout={popout}>
            <Popout
                key='admission'
                id='admission'
                fill='#5a166480'
                edge={12}
            >
                <Admission />
            </Popout>
            <Popout
                key='training'
                id='training'
                fill='#5a166480'
                edge={0}
            >
                <TypeTraining />
            </Popout>
        </PopoutRoot>
    )
}