import { FC } from "react";

import { IconP1, IconP2, IconP3, IconP4, } from "./icons";

import styles from './styles.module.scss'
import { admissionModel } from "widgets/admission";
import { popoutModel } from "shared/ui/popout-root";

export const Courses: FC = () => {

    const onCourse = (c: string) => {
        admissionModel.events.setCourseSelect({
            name: c
        })
        // popoutModel.events.setPopout('admission')
        popoutModel.events.setPopout('training')
    }

    return (
        <div className={styles.courses} id="courses">
            <div className={styles.__in}>

                <div className={styles.title}>
                    Учим IT-профессиям на практике
                </div>

                <div className={styles.sub_title}>
                    Эксперты из IT-индустрии разрабатывают курсы и поддерживают вас на всем пути
                </div>

                <div className={styles.list}>

                    <div
                        onClick={() => onCourse('Python Погружение')}
                        className={`${styles.card} ${styles.p1}`}
                    >
                        <div className={styles.icon}>
                            <IconP1 width={180} height={180} />
                        </div>
                        <div className={styles.content}>
                            <div className={styles.top}>
                                <div className={styles.name}>Python Погружение</div>
                                <div className={styles.period}>
                                    <span>6 месяцев</span>
                                </div>
                                <div className={styles.text}>
                                    <span>Для тех, кто только начинает свой путь в IT и хочет быстро и качественно освоить самый популярный на сегодняшний день язык Python</span>
                                </div>
                            </div>
                            <div className={styles.bottom}>
                                {/* <div className={styles.price}>11000 ₽/мес</div> */}
                            </div>
                        </div>
                    </div>

                    <div
                        onClick={() => onCourse('Python Восхождение')}
                        className={`${styles.card} ${styles.p2}`}
                    >
                        <div className={styles.icon}><IconP2 width={180} height={180} /></div>

                        <div className={styles.content}>
                            <div className={styles.top}>
                                <div className={styles.name}>Python Восхождение</div>
                                <div className={styles.info}>Продвинутый уровень</div>
                                <div className={styles.period}>
                                    <span>6 месяцев</span>
                                </div>
                                <div className={styles.text}>
                                    <span>Уникальный курс, который поможет вам освоить Python и успешно войти в карьеру программиста</span>
                                </div>
                            </div>
                            <div className={styles.bottom}>
                                {/* <div className={styles.price}>12500 ₽/мес</div> */}
                            </div>
                        </div>
                    </div>

                    <div
                        onClick={() => onCourse('Python Эксперт')}
                        className={`${styles.card} ${styles.p3}`}
                    >

                        <div className={styles.icon}><IconP3 width={180} height={180} /></div>

                        <div className={styles.content}>
                            <div className={styles.top}>
                                <div className={styles.name}>Python Эксперт</div>
                                <div className={styles.info}>Глубокие знания</div>
                                <div className={styles.period}>
                                    <span>5 месяцев</span>
                                </div>
                                <div className={styles.text}>
                                    <span>Продвинутые темы, передовые технологии, курс для тех, кто хочет стать настоящим экспертом в Python</span>
                                </div>
                            </div>
                            <div className={styles.bottom}>
                                {/* <div className={styles.price}>14000 ₽/мес</div> */}
                            </div>
                        </div>
                    </div>

                    <div
                        onClick={() => onCourse('Фронтенд Старт')}
                        className={`${styles.card} ${styles.p4}`}
                    >

                        <div className={styles.icon}><IconP4 width={180} height={180} /></div>

                        <div className={styles.content}>
                            <div className={styles.top}>
                                <div className={styles.name}>Фронтенд Старт</div>
                                <div className={styles.info}>Основы Разработки</div>
                                <div className={styles.period}>
                                    <span>6 месяцев</span>
                                </div>
                                <div className={styles.text}>
                                    <span>Познакомьтесь с основами создания красивых, интерактивных веб-страниц и откройте для себя захватывающий мир веб-технологий!</span>
                                </div>
                            </div>
                            <div className={styles.bottom}>
                                {/* <div className={styles.price}>9000 ₽/мес</div> */}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}