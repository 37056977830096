import { createEvent, createStore } from "effector"
import { useStore } from "effector-react"

const useScrollWidth = () => useStore($scrollWidth)

const setScrollWidth = createEvent<number>()
export const $scrollWidth = createStore<number | null>(null)
    .on(setScrollWidth, (_, w) => w)

export const selectors = {
    useScrollWidth,
}

export const events = {
    setScrollWidth
}