import { useState, FC } from "react"
// import { popoutModel } from "shared/ui/popout-root"

import styles from './styles.module.scss'
import { admissionModel } from "..";
import { popoutModel } from "shared/ui/popout-root";

// type TCourseAPI = {
//     name: string
//     phone: string
//     course: string
// }

const training = {
    online_1: 'Онлайн. Индивидуально',
    online_2: 'Онлайн. Закрытая группа',
    online_3: 'Онлайн. Группа',
    offline_1: 'Оффлайн. Индивидуально',
    offline_2: 'Оффлайн. Закрытая группа',
    offline_3: 'Оффлайн. Группа',
}

export const Admission: FC = () => {

    const [name, seteName] = useState({ val: '', edit: 0 });
    const [phone, setPhone] = useState({ val: '', edit: 0 });
    
    const [isSend, setIsSend] = useState(false)

    const course = admissionModel.selectors.useCourseSelect()
    const train_id = admissionModel.selectors.useTrainingSelect()

    if(!train_id) return <></>

    const onName = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.currentTarget;
        let v = value + '.';

        if (v.slice(-3, -1) === "  ") {
            value = value.slice(0, -1);
        }
        seteName({ val: value.slice(0, 13), edit: 1 });
    }

    const onPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.currentTarget;
        let v = value;

        console.log('v', v.length)

        if (v.length === 0) setPhone({ val: '+7', edit: 1 })
        else {
            setPhone({ val: value.slice(0, 20), edit: 1 });
        }
    }

    const sendForm = () => {
        console.log('sendForm')

        if(isSend) {
            popoutModel.events.setPopout(null)
            return
        }

        if (phone.val.length < 4) {
            return
        }

        fetch('https://app2023.ru/ansar/tg.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                name: name.val,
                phone: phone.val,
                course: course?.name || 'Не выбран',
                train: train_id?.id || 0
            })
        })
            // .then(response => response.json())
            .then(result => {

                setIsSend(true)

                console.log('result', result)

                // popoutModel.events.setPopout(null)

            })
    }

    return (
        <div className={styles.admission}>

            <div className={`${styles.__content}`}>

                <div className={styles.feedback}>
                    <div className={styles.__in}>

                        <div className={styles.padding}>

                            <div className={styles.left}>
                                <div className={styles.text}>
                                    Вы выбрали курс «{course?.name}/{training[train_id.id]}». Укажите своё имя и номер телефона и мы скоро с вами свяжемся.
                                </div>
                            </div>

                            <div className={styles.form}>
                                {!isSend ?
                                    <div className={styles.form_layout}>

                                        <div className={styles.input_name}>
                                            <div className={styles.top}>
                                                Имя
                                            </div>
                                            <input
                                                type="text"
                                                name="name"
                                                value={name.val}
                                                onChange={onName}
                                            />
                                            <div className={styles.bottom}>
                                                <NameBottom {...name} />
                                            </div>
                                        </div>

                                        <div className={styles.input_name}>
                                            <div className={styles.top}>
                                                Телефон
                                            </div>
                                            <input
                                                type="tel"
                                                name="phone"
                                                value={phone.val}
                                                onFocus={onPhone}
                                                placeholder="+79001234567"
                                                onChange={onPhone}
                                            />
                                            <div className={styles.bottom}>
                                                <PhoneBottom {...phone} />
                                            </div>
                                        </div>


                                    </div> :
                                    <div className={styles.sendForm}>
                                        Заявка успешно отправлена! Ожидайте ответа на указанный номер!
                                    </div>}

                                <div className={styles.buttonLayout}>
                                    <div
                                        onClick={sendForm}
                                        className={styles.button}
                                    >
                                        <div
                                            className={styles.text}
                                        >
                                            {isSend ? 'Закрыть' : 'Отправить'}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

type TText = {
    val: string
    edit: number
}


const NameBottom: FC<TText> = (text) => {
    if (text.edit === 0) return <span>&nbsp;</span>
    if (text.val.length === 0) return <span className={styles.error}>Пожалуйста, введите название!</span>
    if (text.val.trim().length < 3) return <span className={styles.error}>Минимальная длина 3 символа!</span>
    if (text.val.trim().length > 12) return <span className={styles.error}>Максимальная длина 20 символов!</span>
    return <span className={styles.success}>&nbsp;</span>
}

const PhoneBottom: FC<TText> = (text) => {
    if (text.edit === 0) return <span>&nbsp;</span>
    if (text.val.length > 6 && !/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/.test(text.val)) return <span className={styles.error}>Введен некорректный номер!</span>
    return <span className={styles.success}>&nbsp;</span>
}
