import { appModel } from "app/model";
import React, { useRef, useState } from "react";
// import { useStickerAtom } from "store/sticker";

export const useSlider = (wCard: number) => {

    const refContainer = React.useRef<HTMLDivElement>(null);
    const refCardScroll = React.useRef<HTMLDivElement>(null);

    const [widthCard, setWidthCard] = useState(wCard)

    const refBoxWidth = useRef<HTMLDivElement>(null);
    const pageWidth = appModel.selectors.useScrollWidth() || 0

    const contentWidth = (pageWidth - (refBoxWidth.current?.offsetWidth || 0)) / 2 + 14

    console.log('contentWidth', contentWidth)

    const scrollLeft = () => {
        refCardScroll.current!.scrollBy({ left: -(widthCard + 24), behavior: 'smooth' })
    }
    const scrollRight = () => {
        refCardScroll.current!.scrollBy({ left: widthCard + 24, behavior: 'smooth' })
    }

    const refTimeoutId = useRef<ReturnType<typeof setTimeout>>()

    const onScroll = (callback: (opts: any) => any) => {
        if (refTimeoutId.current) {
            clearTimeout(refTimeoutId.current);
        }
        refTimeoutId.current = setTimeout(callback, 200);
    }

    const scrollByFull = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        let scroll = widthCard + 24;
        let scrollLeft = refCardScroll.current?.scrollLeft || 0

        let remainder = Math.floor(scrollLeft % scroll)

        if (remainder > scroll * 0.5) {
            refCardScroll.current?.scrollBy({ left: Math.floor(scroll - remainder), behavior: 'smooth' })
        } else {
            refCardScroll.current?.scrollBy({ left: -(remainder), behavior: 'smooth' })
        }
    }

    return {
        widthCard,
        refContainer,
        refCardScroll,
        scrollLeft,
        scrollRight,
        onScroll,
        scrollByFull,

        refBoxWidth,
        contentWidth
    }

}