import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

import { useSlider } from './use-slider';
import { IconLeft, IconRight } from 'shared/assets/icons';

// export const CardScrollContext = createContext({} as { width: number })

type TCardScroll = {
    children: ReactNode
    wCard: number
}

const CardsScroll: FC<TCardScroll> = ({ children, wCard }) => {

    const {
        widthCard,
        refContainer,
        refCardScroll,
        scrollLeft,
        scrollRight,
        onScroll,
        scrollByFull,
        refBoxWidth,
        contentWidth
    } = useSlider(wCard)

    return (
        <div
            className={`${styles.cardsScroll}`}
        >

            <div
                ref={refBoxWidth}
                className={`${styles.cardsScroll__box}`} />

            <div
                className={`${styles.cardsScroll__in}`}
                ref={refCardScroll}
                onScroll={(e) => onScroll(() => scrollByFull(e))}
            >
                <div
                    className={`${styles.cardsScroll__content}`}
                    style={{
                        padding: `0px ${contentWidth}px`
                    }}
                    ref={refContainer}
                >
                    {/* <CardScrollContext.Provider
                        value={{ width: widthCard }}
                    > */}
                    {children}
                    {/* </CardScrollContext.Provider> */}
                </div>
            </div>

            <div className={styles.arrows}>
                <div className={styles.__in}>
                    <div
                        className={styles.left}
                        onClick={scrollLeft}
                    >
                        <IconLeft width={34} height={34} />
                    </div>
                    <div
                        className={styles.right}
                        onClick={scrollRight}
                    >
                        <IconRight width={34} height={34} />
                    </div>
                </div>
            </div>

        </div>
    )

}

export default CardsScroll