import { FC } from "react";

import styles from './styles.module.scss'

import Logo from './ansar-logo.png'

export const Main: FC = () => {
    return (
        <div className={styles.main} id="ansar">
            <div className={styles.__in}>

                <div className={styles.info}>

                    <div className={styles.head}>
                        Ansar
                    </div>

                    <div className={styles.sub_head}>
                        программирование для тех, кто хочет получить практические знания
                    </div>

                    <div className={styles.button_box}>

                        <a href="#courses">
                            <div className={styles.button}>
                                Хочу поступить
                            </div>
                        </a>

                    </div>

                    <div className={styles.list}>

                        <div className={styles.li}>
                            <div className={styles.before} />
                            <div className={styles.name}>
                                Программирование с нуля
                            </div>
                        </div>

                        <div className={styles.li}>
                            <div className={styles.before} />
                            <div className={styles.name}>
                                Продвинутый уровень
                            </div>
                        </div>
                        <div className={styles.li}>
                            <div className={styles.before} />
                            <div className={styles.name}>
                                Реальные проекты и кейсы
                            </div>
                        </div>
                        <div className={styles.li}>
                            <div className={styles.before} />
                            <div className={styles.name}>
                                Помощь  в прохождении собеседования
                            </div>
                        </div>
                        <div className={styles.li}>
                            <div className={styles.before} />
                            <div className={styles.name}>
                                Профильное активное комьюнити
                            </div>
                        </div>

                    </div>

                </div>

                <div className={styles.icon}>
                    <div className={styles.box}>
                        <div className={styles.brd1}>
                            <div className={styles.brd2}>
                                <div className={styles.image}>
                                    <img src={Logo} alt="Ansar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}