import { useStore } from "effector-react"
import { createEvent, createStore } from "effector"

type TCourseSelect = {
    name: string
}
export type TTraing =
    | 'online_1'
    | 'online_2'
    | 'online_3'
    | 'offline_1'
    | 'offline_2'
    | 'offline_3'

export type TTraingSelect = {
    id: TTraing
}

const setCourseSelect = createEvent<TCourseSelect | null>()
const setTrainingSelect = createEvent<TTraingSelect | null>()

const useCourseSelect = () => useStore($courseSelect)
const useTrainingSelect = () => useStore($trainingSelect)

export const $courseSelect = createStore<TCourseSelect | null>(null)
    .on(setCourseSelect, (_, course) => course)

export const $trainingSelect = createStore<TTraingSelect | null>(null)
    .on(setTrainingSelect, (_, t) => t)

export const selectors = {
    useCourseSelect,
    useTrainingSelect
}

export const events = {
    setCourseSelect,
    setTrainingSelect,
}