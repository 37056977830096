import CardsScroll from "shared/ui/slider/cards-scroll/ui"

import Card from "shared/ui/slider/card/ui";

import styles from './styles.module.scss';

import User from './images/user.jpg'

export const Teachers = () => {

    return (
        <div className={styles.slider}>
            <div className={styles.__in} id="teachers">

                <div className={styles.title}>
                    Преподаватели и менторы — профессионалы в своей области
                </div>

                <CardsScroll wCard={300}>
                    <Card key='id1'>
                        <div className={styles.box}>
                            <div className={styles.image}>
                                <img src={User} alt="<>" />
                            </div>
                            <div className={styles.name}>
                                Юнус Гелдабаев
                            </div>
                            <div className={styles.description}>
                                Senior Manager Of Artificial Intelligence в Ansar
                            </div>
                        </div>
                    </Card>
                    <Card key='id2'>
                        <div className={styles.box}>
                            <div className={styles.image}>
                                <img src={User} alt="<>" />
                            </div>
                            <div className={styles.name}>
                                Юнус Гелдабаев
                            </div>
                            <div className={styles.description}>
                                Senior Manager Of Artificial Intelligence в Ansar
                            </div>
                        </div>
                    </Card>
                    <Card key='id3'>
                        <div className={styles.box}>
                            <div className={styles.image}>
                                <img src={User} alt="<>" />
                            </div>
                            <div className={styles.name}>
                                Юнус Гелдабаев
                            </div>
                            <div className={styles.description}>
                                Senior Manager Of Artificial Intelligence в Ansar
                            </div>
                        </div>
                    </Card>
                    <Card key='id4'>
                        <div className={styles.box}>
                            <div className={styles.image}>
                                <img src={User} alt="<>" />
                            </div>
                            <div className={styles.name}>
                                Юнус Гелдабаев
                            </div>
                            <div className={styles.description}>
                                Senior Manager Of Artificial Intelligence в Ansar
                            </div>
                        </div>
                    </Card>
                    <Card key='id5'>
                        <div className={styles.box}>
                            <div className={styles.image}>
                                <img src={User} alt="<>" />
                            </div>
                            <div className={styles.name}>
                                Юнус Гелдабаев
                            </div>
                            <div className={styles.description}>
                                Senior Manager Of Artificial Intelligence в Ansar
                            </div>
                        </div>
                    </Card>
                </CardsScroll>
            </div>
        </div>
    )
}